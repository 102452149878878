<template>
  <div class="container-fluid">
    <div class="login-frame" v-if="user">
      <h3>Hello, {{ user.firstname }} {{ user.lastname }}</h3>
      <h3 class="error" v-if="error">There was an error logging you in. Please try again.</h3>
      <card>
        <div class="properties">
          <div class="property" v-if="dataUrl">
            <p class="important-p">
              Please use your authenticator to scan the below QR code. When finished, enter the
              resulting code in the Token field.
            </p>
            <div class="qr-holder">
              <img :src="dataUrl" />
            </div>
          </div>
          <div class="property" v-else>
            <h4 class="title-label">
              Please enter the code from your authenticator in the Token field.
            </h4>
          </div>
          <div class="property">
            <label for="totp" class="title-label">Token</label>
            <input
              name="totp"
              id="totp"
              type="number"
              size="6"
              maxlength="6"
              class="input-text"
              v-model="userToken"
              @keyup="isEnter"
              autofocus
            />
          </div>
          <div class="buttons">
            <base-button iconType="CHECK" v-on:click="validateUserToken()" title="Validate" />
            <base-button type="THEMED" iconType="CANCEL" v-on:click="cancel()" title="Cancel" />
          </div>
        </div>
      </card>
    </div>
  </div>
</template>

<script>
import QRCode from 'qrcode';
import Card from '../components/Card/Card.vue';
import BaseButton from '../components/BaseButton/BaseButton.vue';

export default {
  name: 'TWOFA',
  components: {
    Card,
    BaseButton,
  },
  data() {
    return {
      user: null,
      dataUrl: null,
      userToken: null,
      error: false,
    };
  },
  methods: {
    async validateUserToken() {
      try {
        const response = await this.$utils.http().post('/api/v1/auth/2fa/validate', {
          ss: this.$route.query.ss,
          token: this.userToken,
        });

        if (response.data.token) {
          localStorage.setItem('jwt', response.data.token);

          if (localStorage.getItem('jwt') != null) {
            this.$root.$emit('login');
            if (this.$route.query.nextUrl != null) {
              this.$router.push(this.$route.query.nextUrl);
            } else {
              this.$router.push('/devices/approved');
            }
          }
        }
      } catch (err) {
        this.error = true;
      }
    },
    async getUserBySSToken() {
      try {
        const response = await this.$utils.http().post('/api/v1/auth/2fa/user', {
          ss: this.$route.query.ss,
        });

        this.user = response.data;
        this.$nextTick(() => {
          const token = document.querySelector('#totp');
          token.focus();
        });

        if (this.user.two_factor_temp_secret) {
          // Get the data URL of the authenticator URL
          QRCode.toDataURL(this.user.two_factor_temp_secret.otpauth_url, (err, dataUrl) => {
            this.dataUrl = dataUrl;
          });
        }
      } catch (err) {
        const message = err.response.data.message || 'Unknown error creating 2FA request';
        // eslint-disable-next-line no-alert
        alert(message);
        this.$router.push({ name: 'login' });
      }
    },
    isEnter(e) {
      if (e.key === 'Enter') {
        this.validateUserToken();
      }
    },
    cancel() {
      this.$router.push('/login');
    },
  },
  async mounted() {
    this.getUserBySSToken();
  },
};
</script>

<style scoped>
label {
  display: block;
}
.buttons {
  display: flex;
}
.login-frame {
  position: absolute;
  left: 50%;
  top: 100px;
  transform: translateX(-50%);
  width: 400px;
}
.error {
  color: #e13e3d;
  font-weight: normal;
}
.important-p {
  font-size: 14px;
  color: white;
}
.qr-holder {
  text-align: center;
  padding: 20px 0;
}
</style>
